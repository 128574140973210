/* GENERAL - _general.scss */

* {
  box-sizing: border-box;
}

body {
  background: $body-bg-color;
}

.main {
  padding: 0 0.5rem 0.5rem;
}

.page {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.mdl-layout__content {
  z-index: initial;
}

.mdl-layout__obfuscator {
  z-index: 140;
}

.main-container {
  position: relative;
  flex: 1 1 auto;
  width: 100%;
  background: $main-container-bg-color;
  @media #{$medium-up} {
    padding: 0 1rem;
  }
  @media #{$large-up} {
    padding: 0;
  }
}

.content-width {
  max-width: $content-width;
  margin: 0 auto;
}

ul,
ol,
li,
dd,
dt {
  margin: 0;
  padding: 0;
  list-style: none;
  .std & {
    list-style-position: inside;
    list-style-type: disc;
  }
}

main button,
.button {
  padding: 1em;
  border: none;
  outline: none;
  color: $button-text-color;
  text-transform: uppercase;
  background: $button-bg-color;
  &:hover {
    cursor: pointer;
    background: darken($button-bg-color, 5%);
    transition: ease 0.2s background;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.fullpage-image {
  // This ignores the padding and margin of mdl grid.
  // img {
  //   margin: -16px;
  //   width: calc(100% + 32px);
  //   max-width: none;
  //   margin-bottom: 1rem;
  // }
}

.col-wrapper {
  position: relative;
  clear: both;
}

a.button,
a.link-wishlist,
a.link-compare,
a.link-learn {
  text-decoration: none;
}

a {
  color: $basic-link-color;
  text-decoration: none;
  &:hover {
    color: darken($basic-link-color, $basic-link-hover-percentage);
  }
}

.polar-language-box {
  z-index: 110;
  position: relative;
  margin-top: 2px;
  .polar-language-button {
    margin-top: 0.5em;
    color: $color-grey-dark;
    font-size: 0.9rem;
    vertical-align: center;
  }
  .polar-language-button::after {
    content: " \25BE ";
  }
  .polar-language-list {
    display: none;
    position: absolute;
    border: 1px solid $color-grey-light;
    background: $polarcore-language-bg-color;
    .polar-language-flex {
      display: flex;
      flex-direction: column;
      li:hover {
        background: darken($polarcore-language-bg-color, 5%);
      }
    }
  }
}

.messages {
  padding: 1em 0;
  & > li {
    padding: 1em;
    border: 1px solid $messages-border-color;
    color: $messages-text-color;
    background: $messages-bg-color;
  }
  .success-msg {
    color: $success-msg-text-color;
    background: $success-msg-bg-color;
  }
  .error-msg {
    display: flex;
    color: $error-msg-text-color;
    background: $error-msg-bg-color;
    @media #{$large-up} {
      &::before {
        @include material-icons();
        width: auto;
        margin: 0;
        font-size: 3em;
        content: "\E000";
      }
    }
    & > ul {
      width: auto;
      margin-top: auto;
      margin-bottom: auto;
      margin-left: 1rem;
    }
  }

  .notice-msg {
    color: $notice-msg-text-color;
    background: $notice-msg-bg-color;
  }
}

.error {
  display: flex;
  padding: 1em;
  color: white;
  text-align: left;
  background: $mdl-red-400;
  span {
    margin: auto 1rem;
    text-align: left;
  }
  a {
    margin: 0 0.5rem;
    color: #eaeaea;
    text-decoration: underline;
    &:hover {
      color: #f0f0f0;
    }
  }
  @media #{$large-up} {
    &::before {
      @include material-icons();
      width: 5rem;
      margin: 0;
      font-size: 3em;
      content: "\E000";
    }
  }
  & > ul {
    width: auto;
    margin: 0;
  }
}

.mdl-textfield {
  width: 100%;
}

.mdl-checkbox__tick-outline {
  @include material-animation-default(0.28s);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  transition-property: background;
  mask: url("../images/tick-mask.svg?embed");
  .mdl-checkbox.is-checked & {
    background: $checkbox-color url("../images/tick.svg?embed");
  }
  .mdl-checkbox.is-checked.is-disabled & {
    background: $checkbox-disabled-color url("../images/tick.svg?embed");
  }
}

#map-popup {
  z-index: 200;
  position: absolute;
  width: 30%;
  padding: 2rem;
  border: 1px solid #e6e6e6;
  background: white;
  .btn-cart {
    @include btn-cart($add-to-cart-bg, $add-to-cart-text-color);
  }
}

/// Safari Fixes
input.mdl-textfield__input {
  appearance: none; //Removes outer border on inputs in safari
}

/// Search Index 
.catalogsearch-result-index {
  @media #{$small-only} {
    aside.col-left {
      min-height: 3rem;
    }
    .page-title h1 {
      margin-top: 2rem;
      font-size: 1.4rem;
      text-align: center;
    }
    .toolbar .sorter .sort-by .mobile-sort {
      width: 100%;
    }
    .toolbar .sorter .sort-by .nuva-dropdown-wrapper .nuva-dropdown {
      width: 100%;
    }
  }
}

//Fix for rendering-issue with zoom-levels and mdl-checkboxes
.mdl-checkbox__box-outline {
  top: 15%;
  left: -1%;
}

.shipping-tracking-popup {
  padding: 3rem;
  .button {
    @include clearfix();
    float: right;
    margin: 1rem 0;
  }
  .buttons-set {
    @include clearfix();
  }
}
.tracking-popup-wrapper {
  position: relative;
  padding: 2rem;
  border: 1px solid #f0f0f0;
  .data-table tbody th {
    background: transparent;
  }
  .table-padding {
    padding: 1rem;
    border: 1px solid #eeeeee;
    background: #fafafa;
    .odd {
      background: white;
    }
  }
  [id*=track-history] {
    width: 100%;
    thead tr {
      text-align: left;
    }
  }
  .page-title {
    @include clearfix();
    h1 {
      margin-bottom: 2rem;
      padding: 0;
    }
    .button {
      position: absolute;
      top: 1rem;
      right: 2rem;
    }
  }
}

.data-table {
  width: 100%;
  margin: 1rem 0;
  border-spacing: 0;
  @media #{$small-only} {
    padding: 1rem 0;
  }
  colgroup {
    display: none;
  }
  h3 {
    margin: 0;
    font-size: 1.25rem;
  }
  tfoot {
    border-top: 2px solid rgba(0,0,0,0.05);
    font-size: 1rem;
  }
  td:first-child {
    padding-left: 1rem;
  }
  td:last-child {
    padding-right: 1rem;
    text-align: right;
  }
  .label {
    font-weight: 400;
  }
  th {
    text-align: left;
    text-transform: uppercase;
  }
  tr {
    &:hover {
      td,
      th {
        border-bottom: 1px solid rgba($color-polar, 0.2);
        background: rgba(0,0,0,0.02);
      }
    }
  }
  td,
  th {
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px $table-border solid;
    transition: border-color 0.1s ease-in-out;
    &:first-child {
      padding-left: 1rem;
      border-left: 1px solid $table-border;
    }
    &:last-child {
      padding-right: 1rem;
      border-right: 1px solid $table-border;
      text-align: right;
    }
  }
  tbody,
  tfoot,
  thead {
    tr:first-child {
      td,
      th {
        border-top: 1px solid $table-border;
      }
    }
  }
}
&.customer-account table.data-table {
  td h3 {
    font-size: 1rem;
  }
  tfoot {
    .grand_total td {
      border-bottom: 2px solid rgba(0,0,0,0.1);
    }
  }
}
#product-attribute-specs-table {
  max-width: 600px;
  margin-right: auto;
  margin-left: auto;
}
