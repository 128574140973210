@if $import-labels == true {
  /* MODULES / LABELS - modules/labels/_labels.scss */
  #polarcore {
    %labels {
      .trollweb-labels {
        z-index: 1;
        position: absolute;
        top: 0;
        right: 0;

        .trollweb-label {
          color: #ffffff;

          .discount,
          .new {
            display: block;
            padding: 0.25rem 1rem;
            font-size: 1rem;
            text-align: center;
            background-color: $mdl-red-400;
          }
        }
      }
    }
    .product-img-box {
      .trollweb-labels {
        .trollweb-label {
          .discount,
          .new {
            padding: 0.375rem 1.425rem;
            font-size: 1.275rem;
          }
        }
      }
    }
    .product-img-box,
    .image-wrapper {
      @extend %labels;
      position: relative;
    }
  }
}
