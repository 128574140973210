/* ANIMATION.scss */

@keyframes showsearch {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
}

@keyframes hidesearch {
  0% {
    opacity: 1;
    transform: translateX(0x);
  }
  100% {
    opacity: 0;
    transform: translateX(-100px);
  }
}

@keyframes smoothOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
