/* CATEGORY - _category.scss */

#narrow-by-list {
  @media #{$medium-up} {
    margin-top: 0;
    padding-right: 1rem;
    border-right: 2px #fefefe solid;
  }
  dd {
    margin: 0;
    ol {
      margin-bottom: 1rem;
      padding: 0;
      padding-bottom: 0.25rem;
      border-bottom: 1px solid #eeeeee;
    }
    a {
      color: #333333;
      text-decoration: none;
      .count {
        color: #666666;
        font-size: 0.8rem;
      }
    }
    li {
      list-style: none;
    }
  }
  dt {
    margin-bottom: 0.25rem;
    color: #555555;
    font-size: 1.2rem;
    text-transform: $category-sidebar-title-text-transform;
  }
}

.category-title {
  clear: both;
  margin-top: 1rem;
  padding-left: 1rem;
  text-align: center;
  @media #{$medium-up} {
    text-align: left;
  }
  h1 {
    margin: 0;
    color: #444444;
    font-size: 2.4rem;
  }
}

.category-description {
  display: none;
  margin-bottom: 1rem;
  padding: 0.5rem 1rem;
  font-size: 1.1em;
  @media #{$medium-up} {
    display: block;
  }
}

.category-image {
  display: none;
  @media #{$medium-up} {
    display: block;
  }
}

ul.catblocks {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
  li {
    flex-grow: 1;
    flex-basis: 50%;
    margin: auto;
    text-align: center;
    a {
      color: #333333;
      font-weight: bold;
      text-decoration: none;
      &:hover {
        color: #000000;
      }
    }
    span {
      display: block;
      padding-top: 1em;
      padding-bottom: 2em;
      color: #333333;
    }
  }
}

.toolbar {
  margin: 1.25rem -0.5rem;
  /* background: #FAFAFA; */
  text-transform: $toolbar-text-transform;
  @media #{$medium-up} {
    margin: 0 0 2em 0;
    padding: 0.5em;
    .amount::after {
      display: none; /* Hidden by default */
      margin-right: 0.3em;
      margin-left: 0.3em;
      content: "|";
    }
    .view-mode::after {
      margin-right: 0.3em;
      content: "|";
    }
  }
  .amount {
    display: inline-block;
    order: 10;
    color: #333333;
    font-weight: lighter;
  }
  .limiter {
    display: none; /* Hidden by default */
    /* display: inline-block; */
  }
  .sorter {
    display: flex;
    flex-direction: column;
    @media #{$medium-up} {
      display: block;
    }
    .view-mode {
      display: none; /* Hidden by default */
      /* display: $display-view-mode; */
      float: left;
      label {
        display: none;
      }
    }
    .sort-by {
      order: 5;
      float: none;
      @media #{$medium-up} {
        float: right;
      }
      select {
        display: none;
        text-transform: $toolbar-text-transform;
      }
      label {
        @media #{$medium-up} {
          display: $display-sort-by-label !important;
          margin-right: 0.5rem;
        }
      }
      .desc,
      .asc {
        display: none; /* Hidden by default */
        text-decoration: none;
      }
      .asc::after {
        content: " \25B4";
      }
      .desc::after {
        content: " \25BE";
      }
      .nuva-dropdown-wrapper {
        @media #{$small-only} {
          position: static;
        }
        .nuva-dropdown {
          right: 8px;
          left: auto;
          width: 150px;
          @media #{$small-only} {
            top: 0;
          }
        }
        @media #{$small-only} {
          float: right;
          width: 100%;
          margin-top: -3rem;
          .nuva-dropdown {
            width: 47.5%;
            li {
              padding: 0.5rem;
            }
          }
        }
      }
    }
    a.desc span,
    a.asc span {
      display: $display-asc-desc;
    }
    & > div {
      width: 100%;
      text-align: center;
      @media #{$medium-up} {
        width: auto;
      }
    }
  }
  .pager {
    display: none;
    clear: both;
    .pages > ol {
      list-style: none;
      text-align: center;
      li {
        display: inline-block;
        margin-right: 1em;
      }
    }
    .pages strong {
      display: none;
    }
  }
}

.toolbar-bottom {
  .toolbar {
    padding: 0;
    background: none;
    .pager {
      display: block;
      padding: 0.5rem;
      background: #fafafa;
    }
    .sorter {
      display: none;
    }
  }
}

/* Polarcore SlideToggle */
.col-wrapper aside.sidebar {
  @media #{$small-only} {
    .block-title {
      width: 100%;
      height: 3.25rem;
      margin: 0;
      padding: 0.75rem 1rem;
      border: 1px solid $color-polar;
      color: #ffffff;
      line-height: 1.75rem;
      text-align: center;
      text-transform: uppercase;
      background: $color-polar;
      &:hover {
        color: #ffffff;
      }
      span {
        width: 100%;
      }
    }
  }
  .block-subtitle--filter {
    @extend .block-title;
    width: 47.5%;
    &:before {
      @include material-icons();
      display: inline-block;
      margin-right: 0.5rem;
      border: 1px solid $color-polar;
      font-size: 1.25rem;
      vertical-align: text-bottom;
      content: "\E152";
    }
  }
}
