@if $import-quickshop == true {
  /* MODULES / QUICKSHOP - modules/quickshop/_quickshop.scss */
  #polarcore {
    #quickshop.quickshop-container {
      @media #{$small-only} {
        padding: 1rem;
      }
    }
  }
}
