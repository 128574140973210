/* WIDGET - _wisget.scss */

.widget-new-products {
  .widget-title {
    //mixins.scss
    @include h2-with-line();
  }
  .widget-products {
    .products-grid {
      .add-to-links {
        display: flex;
        flex-direction: column;
        li {
          width: 100%;
          .inner {
            display: block;
          }
        }
      }
    }
  }
}

.widget-viewed {
  .widget-title {
    //mixins.scss
    @include h2-with-line();
  }
}


.title-with-line {
  //mixins.scss
  @include h2-with-line();
}

.banner-area {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  .banner-col-one {
    width: 100%;
    @media #{$large-up} {
      width: 65.5%;
    }
  }
  .banner-col-two {
    width: 100%;
    @media #{$medium-only} {
      display: flex;
      margin-top: 1rem;
    }
    @media #{$large-up} {
      width: 34.5%;
    }
    .banner-wrapper-two {
      margin-top: 2%;
      @media #{$medium-only} {
        width: 50%;
        margin-top: 0;
        margin-left: 0;
        padding-right: 1%;
      }
      @media #{$large-up} {
        margin-top: 0;
        margin-left: 2%;
      }
    }
    .banner-wrapper-three {
      @media #{$small-only} {
        margin-top: 2%;
      }
      @media #{$medium-only} {
        max-width: 50%;
        margin-top: 0;
        margin-top: 0;
        padding-left: 1%;
      }
      @media #{$large-up} {
        margin-top: 2%;
        margin-left: 2%;
      }
    }
  }
}

ul.category-button li {
  width: calc(50% - 1.1rem);
  margin: 0.5rem;
  border-radius: 4px;
  list-style: none;
}

ul.category-button {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 1rem;
  text-align: center;
  flex-diretion: row;
  li {
    height: auto;
    color: $category-button-color;
    font-weight: 200;
    font-size: 1rem;
    font-family: "Lato", sans-serif !important;
    background: $category-button-bg;
    box-shadow: 0 2px 4px rgba(0,0,0,0.25);
    a {
      display: inline-block;
      width: 100%;
      margin: 0;
      padding: 0.5rem;
      color: $category-button-color;
      font-size: 1rem;
      text-transform: uppercase;
    }
  }
  .full-width {
    width: 100%;
  }
}

.nuva-dropdown-wrapper {
  display: inline-block;
  position: relative;
  user-select: none;
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  a {
    width: 100%;
    padding: 0.5em;
    border: solid 1px #f0f0f0;
    border-radius: 2px;
    text-align: center;
    cursor: pointer;
    background: white;
    &::after {
      display: none; // display: inline-block;
      content: "<>";
      transform: rotate(90deg);
    }
  }
  @media #{$small-only} {
    > a {
      position: absolute;
      top: 16px;
      right: 8px;
      float: right;
      width: 47.5%;
      height: 3.25rem;
      margin: 0;
      padding: 0.75rem 1rem;
      border: 1px solid $color-polar;
      border-radius: 0;
      overflow: hidden;
      color: $color-polar;
      line-height: 1.75rem;
      text-overflow: ellipsis;
      text-transform: none;
      white-space: nowrap;
      &:before {
        @include material-icons();
        display: inline-block;
        margin-right: 0.5rem;
        font-size: 1.25rem;
        vertical-align: text-bottom;
        content: "\E164";
      }
    }
  }
  .nuva-dropdown {
    z-index: 30;
    position: absolute;
    top: -0.5em;
    left: 0.2em;
    border: #f0f0f0 1px solid;
    color: $mdl-grey-600;
    background: #ffffff;
    box-shadow: 0 3px 4px rgba(0, 0, 0, 0.2);
    transition: ease 0.2s;;
    li {
      z-index: 50;
      position: relative;
      width: 100%;
      padding: 0.5em 1em;
    }
    li:hover {
      cursor: pointer;
      background: #f0f0f0;
    }
  }
}

.nuva-show {
  opacity: 1;
  visibility: visible;
}

.nuva-hide {
  opacity: 0;
  visibility: hidden;
}

#polarcore #bubble-layer-overlay {
  display: flex;
  background: rgba(255,255,255, 0.05);
  transition: ease-in-out 0.2s;
  .mdl-spinner {
    width: 64px;
    height: 64px;
  }
}

.embed-container {
  position: relative;
  max-width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
#polarcore .products-grid.slick-slider {
  .slick-dots {
    position: static;
    bottom: initial;
  }

  .slick-track:before,
  .slick-track:after {
    font-family: initial;
  }

  .slick-slide {
    outline: 0;
  }

  .slick-next {
    right: 0;
  }

  .slick-prev {
    left: 0;
  }

  .slick-next,
  .slick-prev {
    display: flex !important;
    z-index: 80;
    position: absolute;
    width: 3rem;
    height: auto;
    margin: auto;
    background: rgba(255, 255, 255, 0.45) !important;
    &:hover {
      background: rgba(0,0,0, 0.05) !important;
    }
  }

  .slick-next:before {
    transform: rotate(180deg);
  }

  .slick-next:before,
  .slick-prev:before {
    display: inline-block;
    height: 100%;
    margin: auto;
    color: #222222;
    font-size: 3rem;
    font-family: "Material Icons" !important;
    font-family: initial;
    content: "\E314";
    background: none;
  }
}
