#polarcore {
    &.tco.tco-onestep .tco-wrapper #checkout-review-table {
        @media #{$small-only} {
            table, thead, tbody, th, td, tr { 
                display: block; 
            }
            
            /* Hide table headers (but not display: none;, for accessibility) */
            thead tr { 
                position: absolute;
                top: -9999px;
                left: -9999px;
            }
            
            tr { 
                border: 0; 
            }
            
            td { 
                border: none;
                position: relative;
                text-align: center;
                display: flex;
                flex-direction: column;
                &:first-child {
                    width: auto;                    
                }
                h3, dl, img {
                    margin: 0 auto;
                    text-align: center;
                }
            }
            tbody {
                tr {
                    margin-bottom: 0.5rem;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    padding-top: 1rem;
                    background: #fafafa;
                    td {
                        display: flex;
                        &:nth-child(1) {width: 25%; margin-bottom: 1rem;}
                        &:nth-child(2) {width: 75%; margin-bottom: 1rem;}
                        &:nth-child(3) {width: 50%;}
                        &:nth-child(4) {
                            width: 50%;
                            position: relative;
                            &:before {
                                position: absolute;
                                content: "x";
                                font-size: 0.6rem;    
                                left: 0;
                            }
                        }
                        &:nth-child(5) {width: 100%;}
                        &:nth-child(3), &:nth-child(4), &:nth-child(5) {
                            padding: 1rem 0;
                            border-top: rgba(0,0,0,0.05) 1px solid;
                        }
                        .item-options {
                            margin: auto;
                            dt {margin-top: 1rem; font-weight: bold;}
                        }
                    }
                }
            }
            tfoot {
                tr {
                    border-bottom: #F0F0F0 1px solid;
                    margin: 1rem 0;
                    display: flex;
                }
                td, td:first-child {
                    margin: 0;
                    width: 50%;
                    text-align: left;
                    &:last-of-type {
                        text-align: right;
                    }
                }
            }
        }
    }
}
