/* SIDEBAR - _sidebar.scss */

.sidebar {
  @media #{$small-only} {
    width: 100%;
    margin: 8px 0 -8px;
  }
  @media #{$medium-up} {
    padding: 0;
  }
  .block-viewed {
    text-align: center;
  }
  .block {
    .block-title {
      display: block;
    }
    @media #{$small-only} {
      .block-title:not(.active) + .block-content {
        display: none;
      }
    }
  }
}
