@if $import-tco == true {
  /* MODULES / TCO - modules/tco/_tco.scss */
  body.tco.tco-onestep {
    #login-step {
      a.btn-close {
        border: 0;
        color: white;
        background: none;
        &:hover {
          color: #afafaf;
        }
      }
    }
    #login-step-login {
      input {
        min-width: 300px;
      }
    }
    p.login-link {
      margin-bottom: 3rem;
      padding-left: 0.5rem;
    }
    .please-wait {
      display: flex;
      img {
        opacity: 0.2;
        margin: auto;
        padding: 2rem;
      }
    }
    .main-container.col1-layout.checkout {
      margin-top: 0;
      margin-bottom: 3rem;
      //border-bottom: 0.5rem solid #2c313e;
    }
    .checkout .logo {
      max-width: 180px;
    }
    .checkout .back {
      margin-top: 1rem;
    }
    .tco-wrapper .section .step-title,
    .tco-wrapper .step-title {
      border: 0;
      border-bottom: 2px solid $color-polar;
      border-radius: 0;
      //background: #2c313e; 
      background: none;
      box-shadow: none;
      h2 {
        color: $color-polar;
        font-weight: 300;
        text-transform: uppercase;
      }
    }
    .tco-wrapper {
      #login-step-login {
        & > p {
          margin-top: 2rem;
        }
      }
      button.btn-checkout {
        float: right;
        border-radius: 0;
        font-size: 1.2rem;
        background: $add-to-cart-bg;
        transistion: background ease-in-out 0.2s;
        @media #{$small-only} {
          width: 100%;
        }
        &:hover {
          background: darken($add-to-cart-bg, 5%);
        }
        &:after {
          @include material-icons();
          display: inline;
          position: relative;
          top: -2px;
          font-size: 2rem;
          line-height: 1rem;
          vertical-align: middle;
          content: "\E315";
        }
      }
      .login .form-list button.button {
        background: $color-polar;
        transistion: background ease-in-out 0.2s;
        &:hover {
          background: darken($color-polar, 5%);
        }
      }
      #checkout-shipping-method-load {
        .radio {
          margin-top: 0px;
          margin-right: 3px;
        }
      }
      #checkout-review-table tbody td:first-child {
        width: 64px;
      }
      .item-options {
        text-align: left;
      }
      .data-table .product-name {
        line-height: 1.4em;
        text-align: left;
      }
      label {
        color: #666666;
        font-weight: 400;
      }
      dd {
        margin-bottom: 1rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
      dt {
        color: #666666;
        font-weight: 400;
      }
      input + label {
        padding: 0;
      }
      input.input-text {
        border: 0;
        border-bottom: 2px solid #f0f0f0;
        outline: none;
        box-shadow: none;
        &:focus {
          border: 0;
          border-bottom: solid $color-polar 2px;
        }
      }
      input[type="checkbox"] {
        &:focus + label {
          color: darken($color-polar, 10%);
        }
      }
      input:checked + label {
        color: lighten($color-polar, 10%);
      }
      .form-list .field {
        width: 100%;
        padding: 0;
      }
      .step {
        border: 1px solid #f0f0f0;
        .number {
          border: 0;
          border-radius: 2px;
          font-style: Verdana;
          line-height: 1.5rem;
          text-align: center;
          box-shadow: none;
        }
      }
      .wide {
        margin-bottom: 1.5rem;
      }
    }
  }

  body.tco .tco-wrapper label[for="shipping:country_id"] + .input-box,
  body.tco .tco-wrapper label[for="billing:country_id"] + .input-box,
  body.tco .tco-wrapper label[for="billing-address-select"] + .input-box {
    display: flex;
    position: relative;
    max-width: 100%;
    margin-bottom: 1rem;
    padding-right: 2rem;
    border: 1px solid #e0e0e0;
    text-overflow: ellipsis;
    background: white;
    &:after {
      @include material-icons();
      position: absolute;
      right: 0rem;
      height: 2.5rem;
      margin: auto;
      padding: 0rem 0.5rem;
      color: #444444;
      font-size: 1.2rem;
      line-height: 2.5rem;
      vertical-align: middle;
      pointer-events: none;
      content: "\E313";
      background: rgba(0, 0, 0, 0.05);
      user-select: none;
    }
    .validation-advice {
      top: -1.5rem;
      right: 0;
    }
    select {
      position: relative;
      width: 100%;
      height: 2.5rem;
      padding-right: 2rem;
      border: 0;
      outline: none;
      background: transparent;
      appearance: none;
      @media #{$medium-up} {
        padding: 0.75rem;
      }
      &:hover {
        cursor: pointer;
        background: darken(#ffffff, 1%);
      }
      &:disabled {
        color: #444444;
        background: darken(#e0e0e0, 10%);
      }
    }
  }


  body.tco.tco-onestep .tco-wrapper table.data-table td.a-right.last {
    text-align: right;
  }

  body.tco.tco-onestep .data-table {
    td:first-child {
      padding-left: 1rem;
    }
    td:last-child {
      padding-right: 1rem;
      text-align: right;
    }
    th {
      text-align: left;
      text-transform: uppercase;
    }
    td,
    th {
      padding-top: 1rem;
      padding-bottom: 1rem;
      border-bottom: 1px $table-border solid;
      transition: border-color 0.1s ease-in-out;
      &:first-child {
        padding-left: 1rem;
        border-left: 1px solid $table-border;
      }
      &:last-child {
        padding-right: 1rem;
        border-right: 1px solid $table-border;
        text-align: right;
      }
    }
    tbody,
    tfoot,
    thead {
      tr:first-child {
        td,
        th {
          border-top: 1px solid $table-border;
        }
      }
    }
  }
}
