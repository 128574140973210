/* POLAR FLEX GRID - _polar-flex-grid.scss */

[class*="polar-flex-grid-"] {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
[class*="polar-flex-grid-"] > li {
  flex-grow: 0;
  flex-shrink: 0;
}

@for $i from 1 through 12 {
  .polar-flex-grid-#{$i} > li {
    width: 100% / $i;
  }
}

@media #{$small-only} {
  @for $i from 1 through 12 {
    .s-polar-flex-grid-#{$i} > li {
      width: 100% / $i;
    }
  }
}

@media #{$medium-only} {
  @for $i from 1 through 12 {
    .m-polar-flex-grid-#{$i} > li {
      width: 100% / $i;
    }
  }
}

@media #{$large-up} {
  @for $i from 1 through 12 {
    .l-polar-flex-grid-#{$i} > li {
      width: 100% / $i;
    }
  }
}
