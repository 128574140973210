/* ACCOUNT - _account.scss */

.customer-account-login {
  h1 {
    margin: 0;
  }
  .new-users,
  .registered-users {
    padding: 1rem 2rem;
    background: #fafafa;
    h2 {
      margin-top: 0;
      margin-bottom: 0;
    }
    a.mdl-button {
      color: #000000;
    }
  }
  ul.benefits {
    margin-bottom: 1em;
    padding-left: 2em;
    li {
      list-style: square;
    }
  }
  .forgot-password {
    margin-bottom: 1rem;
    margin-left: 0;
  }
  .registered-users {
    a.mdl-button {
      float: right;
      clear: left;
    }
  }
}

.customer-account {
  #my-orders-table {
    tr {
      height: 4rem;
    }
  }
  .data-table {
    border-spacing: 0;
    thead th {
      border-bottom: solid 2px $color-polar;
    }
    tr:nth-child(even) {
      background: rgba(0,0,0,0.03);
    }
  }
  #my-downloadable-products-table,
  .data-table {
    width: 100%;
  }
  #reorder-validate-detail {
    padding: 1rem 0;
    li {
      display: flex;
      input.checkbox {
        width: 10%;
      }
      .product-name {
        width: 90%;
      }
    }
  }
  h2 {
    margin-bottom: 0.5rem;
    font-size: 1.5rem;
  }
  h3 {
    margin: 0;
    padding-top: 0.5rem;
    font-size: 1.3rem;
  }
  .col-left {
    @media #{$medium-up} {
      margin-top: 42px;
    }
  }
}

.remember-me-popup {
  display: none;
}
