#polarcore {
    .usp-below-navigation {
        margin: 0 -0.5rem;
        ul {
            display: flex;
            flex-wrap: wrap;
            border-bottom: none;

            @media #{$medium-up} {
                border-bottom: 1px solid #eee;
            }

            li {
                width: 100%;
                padding: 0.75rem;
                border-top: 1px solid #eee;
                border-bottom: 1px solid #eee;
                text-transform: uppercase;
                text-align: center;
                font-weight: 100;
                display: flex;
                align-items: center;
                justify-content: center;

                a {
                    font-weight: 100;
                    text-transform: uppercase;
                    text-decoration: underline;
                }

                &:not(:last-child) {
                    border-bottom: none;
                }

                @media #{$medium-up} {
                    width: inherit;
                    flex: 1;
                    border-top: none;
                    border-bottom: none;
                    &:not(:last-child) {
                        border-right: 1px solid #eee;
                    }
                }


            }
        }
    }
}
